<template>
  <div>
    <v-row class="ma-0">
      <v-col cols="12" v-for="(template, index) in templates" :key="`t${index}`" class="pb-0">
        <v-card :color="index % 2 ? 'blue-grey lighten-5' : 'brown lighten-5'" tile>
          <v-textarea
            class="px-2 pt-4 pb-0"
            v-model="templates[index]"
            outlined
            label="Template message"
            rows="1"
            color="teal accent-4"
            clearable
            auto-grow
            hide-details
          ></v-textarea>

          <v-card-actions class="py-0">
            <v-spacer />
            <v-btn icon @click="move(index, index - 1)" v-if="index > 0" class="px-0">
              <v-icon>mdi-arrow-up-bold-circle-outline</v-icon>
            </v-btn>
            <v-btn icon @click="move(index, index + 1)" v-if="index < templates.length - 1" class="px-0">
              <v-icon>mdi-arrow-down-bold-circle-outline</v-icon>
            </v-btn>
            <v-btn icon @click="removeFromTemplatesByIndex(index)" color="red darken-1" class="px-0">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" class="pb-2">
        <v-card color="teal accent-1" tile>
          <v-textarea
            class="pa-2 pt-4"
            v-model="newTemplate"
            outlined
            label="New template message"
            rows="1"
            color="teal accent-4"
            clearable
            auto-grow
            hide-details
          ></v-textarea>

          <v-card-actions>
            <v-spacer />
            <v-btn icon @click="createTemplate" color="teal accent-4" v-if="newTemplate">
              <v-icon>mdi-plus</v-icon> Add
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    newTemplate: null,
    templates: JSON.parse(localStorage.getItem("templates")) || []
  }),

  watch: {
    templates: function() {
      console.log("set templates");
      localStorage.setItem("templates", JSON.stringify(this.templates));
    }
  },

  methods: {
    removeFromTemplatesByIndex: function(index) {
      let templates = JSON.parse(localStorage.getItem("templates")) || [];
      templates.splice(index, 1);
      localStorage.setItem("templates", JSON.stringify(templates));
      this.templates = templates;
    },
    createTemplate: function() {
      this.templates.push(this.newTemplate);
      this.newTemplate = null;
    },
    move: function(index, newIndex) {
      let t = this.templates[index];
      this.templates.splice(index, 1);
      this.templates.splice(newIndex, 0, t);
    }
  }
};
</script>
